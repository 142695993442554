import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import HomeFooter from '../../components/Footer/HomeFooter';
import ApiPath from '../../constants/api';
import styles from './styles.module.css';
import { isValidEmailId } from '../../utils/common';
import { Button, TextField } from '@walmart-web/livingdesign-components';
import * as Icon from '@livingdesign/icons';
import apiCall from '../../utils/api';
import API, { API_STATUS_CODE } from '../../constants/common';
import { logger } from '../../utils/logger';

const Waitlist = () => {
  const [email, setEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPartOfCommunity, setIsPartOfCommunity] = useState(false);
  const [source, setSource] = useState('');
  const location = useLocation();

  const isValidMedia = (value: string) => {
    const regex = /^[a-zA-Z0-9@._+-]+$/;
    if (!regex.test(value)) {
      return false;
    }
    return true;
  };

  const onJoin = () => {
    if (!isValidEmailId(email)) {
      setValidationMessage('Please enter a valid email that is associated with your Walmart.com account.');
      return;
    }
    const payload = { emailId: email, mediaChannel: source };
    logger.clickStream('join_waitlist_btn', {email, source});
    apiCall(
      `${ApiPath.v1}${'waitlist/subscribe'}`,
      API.METHOD.POST,
      { ...payload },
      false
    )
      .then((response) => {
        if (response?.status === API_STATUS_CODE.CREATED) {
          setIsSubscribed(true);
          setValidationMessage('');
          setEmail('');
          setIsPartOfCommunity(false);
        } else if (response?.status === API_STATUS_CODE.RATE_LIMIT) {
          setValidationMessage(
            'We have reached the maximum number of requests for the day. Please come back tomorrow to join our waitlist!'
          );
        } else if (response?.error?.message?.includes('Validation Failed')) {
          setValidationMessage(response?.error?.detail?.emailId);
        } else {
          if (
            response?.error?.message?.includes(
              'already a member of our community'
            )
          ) {
            setIsPartOfCommunity(true);
          }
          setValidationMessage(
            response?.error?.message || response?.message || response?.error
          );
        }
      })
      .catch((err) => {
        setValidationMessage('Something went wrong');
      });
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    setValidationMessage('');
    setIsPartOfCommunity(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let utmsource = params.get('utm_source') || '';
    if(!isValidMedia(utmsource)){
      utmsource = '';
    }
    setSource(utmsource);
    logger.analytics('waitlist_loaded', {utmsource});
  }, []);

  const PageHeader = () => (
    <header>
      <nav className={styles.nav}>
        <div className={styles.landingWrapper}>
          <div className={styles.logo}>
            <img src={'assets/images/white-logo.png'} alt={'Spark'} />
            <p>Customer Spark</p>
          </div>
          <Link to="login" className={styles.login}>
            Log in
          </Link>
        </div>
      </nav>
    </header>
  );

  if (isSubscribed)
    return (
      <>
        <PageHeader />
        <div className={styles.confirmation}>
          <div className={styles.mainContainer}>
            <div className={styles.row}>
              <div className={`${styles.col12} ${styles.thankyou}`}>
                <div className={styles.content}>
                  <img src={'/assets/images/walmart-logo.png'} alt={'Spark'} height={100} />
                  <h2 className={`${styles.marginTop30}`}>
                    You’ve been added to our waitlist!
                  </h2>
                  <p className={`${styles.marginTop30}`}>
                    If you qualify, we will send you an invitation to your email
                    associated with your Walmart.com account.<br/>
                    Thank you for your interest on becoming a Walmart Customer Spark Community
                    Member.
                  </p>
                  <Button
                    size="small"
                    className={`${styles.marginTop30}`}
                    variant="primary"
                    isFullWidth={false}
                    href='/'
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <PageHeader />
      <div className={styles.mainContainer}>
        <div className={styles.row}>
          <div
            className={`${styles.col} ${styles.sm12} ${styles.md6} ${styles.marginTop20}`}
          >
            <h1>
              Walmart Customer Spark Community: <br />
              Share Your Opinions, Earn Rewards, and Make an Impact!
            </h1>
            <p className={`${styles.marginTop30} ${styles.text}`}>
              The Walmart Customer Spark Community is an exclusive, invitation only community. 
            </p>
            <p className={`${styles.marginTop30} ${styles.text}`}>
              <span className={styles.fwb}>Join our waitlist </span>to increase
              your chances of receiving an invitation!
            </p>
            <div>
              <TextField
                className={`${styles.input} ${styles.marginTop10}`}
                disabled={false}
                placeholder="Enter your email"
                leadingIcon={<Icon.Email />}
                onChange={handleChange}
                readOnly={false}
                size="large"
                error={validationMessage}
                type="email"
                label={''}
                value={email}
                helperText="Please enter the email associated with your Walmart.com account."
              />
              {isPartOfCommunity && (
                <p className={styles.error}>
                  Please <Link to="login">log in.</Link>
                </p>
              )}
            </div>
            <Button
              size="large"
              className={`${styles.marginTop30} ${styles.button}`}
              disabled={!email}
              variant="primary"
              isFullWidth={false}
              onClick={onJoin}
            >
              Join waitlist
            </Button>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <img
              src={'/assets/images/waitlist/banner.png'}
              width={'100%'}
              className={styles.bannerImage}
              alt="Waitlit"
            />
          </div>
        </div>
        <div className={styles.paddingLeft10}>
          <h2>Who are we? How does it work?</h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/whoarewe.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Who are we?</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  We are a community of carefully selected individuals who
                  represent the voice of Walmart shoppers. Once members join our
                  community, they immediately participate in activities & earn
                  rewards.
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/howdoeswork.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>How does it work?</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  Most activities take no longer than a few minutes to complete!
                  After each activity is completed, members instantly earn
                  points that can be used towards Walmart eGift cards!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.marginTop30} ${styles.paddingLeft10}`}>
          <h2>What is in it for me?</h2>
        </div>
        <div className={styles.row}>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/reward.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Earn rewards for every completed activity</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  As a member, you would have opportunities to provide your
                  feedback to earn points that you can redeem for Walmart eGift
                  cards.
                </p>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.sm12} ${styles.md6}`}>
            <div className={`${styles.row} ${styles.card}`}>
              <div className={`${styles.col3}`}>
                <img
                  className={styles.cardImg}
                  src={'/assets/images/waitlist/impact.png'}
                  alt=""
                />
              </div>
              <div className={`${styles.col9}`}>
                <h3>Drive impact for the brands you love!</h3>
                <p className={`${styles.marginTop10} ${styles.text}`}>
                  Members voice their opinions about their favorite brands at
                  Walmart. As a member, your feedback could be used to make
                  decisions about product improvements. <span className={styles.fwb}>Your voice matters!</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <HomeFooter />
      </div>
    </>
  );
};

export default Waitlist;
